import React, { Fragment } from 'react'

import BasicTitleText from './components/BasicTitleText'
// import DoubleImage from './components/DoubleImage'
import { cre } from '../../utils/data'
import {
  cre1,
  cre2,
  home,
  quote,
} from '../../images/cre'

const CRESimple = () => (

  <Fragment>

    <BasicTitleText title='product' textArray={cre.product} />

    <div className='cre-process'>
      <div className='compress'>
        <div className='item'>
          <div className='image'
            style={{ backgroundImage: `url(${cre1})`}} >
          </div>
        </div>
        <div className='item'>
          <div className='image'
            style={{ backgroundImage: `url(${cre2})`}} >
          </div>
        </div>
      </div>
    </div>

    <BasicTitleText title='process' textArray={cre.process} />

    <div className='cre-design'>
      <div className='compress'>
        <div className='item top-align'>
          <div style={{ backgroundImage: `url(${home})`}} ></div>
        </div>
        <div className='item bottom-align'>
          <div style={{ backgroundImage: `url(${home})`}} ></div>
        </div>
        <div className='item left-align'>
          <div style={{ backgroundImage: `url(${quote})`}} ></div>
        </div>
      </div>
    </div>

    <BasicTitleText title='conclusion' textArray={cre.conclusion} />

  </Fragment>
)

export default CRESimple
