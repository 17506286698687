import React from 'react'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlickCarousel = ({ photoArray }) => {
  const settings = {
    slidesToShow: 4,
    autoplay: true,
    className: 'nec-carousel',
    arrows: false,
    responsive: [
      {
        breakpoint: 1999,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }
  return (
    <Slider {...settings}>
      {photoArray.map((photo, index) => (
        <div key={index} className='nec-carousel-item'>
          <div
            className='nec-carousel-image'
            style={{ backgroundImage: `url(${photo})`}}
          ></div>
        </div>
      ))}
    </Slider>
  )
}

export default SlickCarousel
