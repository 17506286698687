
export const slideTime = 500
export const activeSlug = 'nec'
export const homeText = 'CoMedia designs, develops and innovates for companies large and not yet large'

export const projects = {
  'nec': {
    name: 'NEC GuestView Guide',
    slug: 'nec',
    title: 'The future of vacation rentals',
    // subtitle: 'New product for the vacation rental market',
    alignment: 'center',
    keywords: ['Product Design', 'Identity Design', 'Marketing Website Design', 'Research', 'Product Development'],
    description: [
      'NEC and CoMedia partnered to create a new and viable solution for the vacation rental market. We designed and developed the product beginning from ideation and user research though to market and pre-sales.',
    ],
    product: [
      'The GuestView Guide is a screen device that displays all the information a guest needs during their stay at a vacation rental at their fingertips. The touchscreen features videos on how to use appliances, house tips and rules, nearby restaurants, shopping, as well as activities. When not in use, the hi-res screen displays beautiful images of the area, adding an aesthetically pleasing and modern look to the property.',
    ],
    process: [
      'Our first requirements included validating the concept and identifying features for the MVP. We conducted user surveys, in-person interviews, and usability testing to ask key questions on the most essential features for the new product. User feedback continues to be collected and used to refine product features and usability. In a market with complex needs and requirements, rapid iteration was crucial to meeting our goals.',
    ],
    process2: [
      'There were many challenges to create a brand new product from ideation to commercialization. The product required both hardware and software development, incorporating different components such as sensors, speakers, and of course the display itself.',
      'CoMedia also designed and built the GuestView Guide management console for property managers to conveniently add and update content to their devices from their laptops and mobile phones.',
    ],
    // process3: [
    //   'There were many challenges to create a brand new product from ideation to commercialization. The product required both hardware and software development, incorporating different components such as sensors, speakers, and of course the display.',
    //   'Ergonomics was an important consideration. We did a lot of testing to get it right and ensure that the product was comfortable to use.',
    //   'The property managers utilize many different software solutions too ervice properties. Our development team incorporated multiple APIs and solutions that were essential to create the most useful product for customers.',
    // ],
    conclusion: [
      "As a full product team, CoMedia has the capability to meet our clients' needs from concept to reality. Starting from in-person UX research, ethnographic study & interviews, we ensure that we fully understand the market and user needs. We work quickly on innovation design & development to build a working prototype and use testing to refine our designs.",
      'Real users have given the GuestView Guide rave reviews as we continue to prepare the device and software to scale up for production and sales.',
    ],
  },
  'vodafone': {
    name: 'Vodafone MyComms',
    slug: 'vodafone',
    title: 'Enterprise Communication Solved',
    // subtitle: 'Enterprise Innovation',
    alignment: 'footnote',
    keywords: ['Product Design', 'Identity Design', 'Marketing Website Design', 'Research', 'Product Development'],
    description: [
      'With the growth of alternative communication channels, Vodafone Global Enterprise presents a unified view through its MyComms app. After building a working prototype, VGE asked CoMedia to take it to the next level of user experience.',
    ],
    product: [
      'The project needed a complete redesign to fully integrate features with an intuitive and attractive new interface. In addition, the app needed to be consumer-friendly while managing corporate utilitarian sensibilities. This required redefining the design language, UX/UI, and visual elements.'
    ],
    process: [
      'Before starting, we identified that design efforts needed to work in conjunction with development in order to be successful. Going through a phased approach to match the development team sprints, we were able to build as a cohesive unit.',
      'Before anything went on screen, CoMedia worked on the basics – competitive research, whiteboards, and pencil and paper. In our research, we looked at other communication/productivity tools to see what features worked and what didn’t.',
      'With this in mind, we then worked with Vodafone to whiteboard key experiences. We based the user experience around the contacts list and the ability to quickly navigate to a desired activity, such as chat, call or join a meeting. Taking the whiteboard and paper ideas, the design team created high fidelity UI mock-ups. From there, we were able to build in the visual elements and tie the deliverables to the development timeline.',
    ],
    branding: [
      'Although we moved quickly to match development sprints, we also determined branding in the early stages of the project and continued to refined as we progressed.',
      'In addition, we were asked to define the design for their companion bot, Ruby. Taking inspiration from our favorite pop culture icons we took design cues from 2001: A Space Odyssey, The Jetsons, and Doctor Who.',
    ],
    // visuals: [
    //   'Enterprise software needs to be easy to use and meet the needs of stakeholders. The element so often forgotten in applications like MyComms is that there are actual individuals who need to use the product. It has to be more than a tool to make a company more efficient.',
    // ],
  },
  'cre': {
    name: 'CRE Simple',
    slug: 'cre',
    title: 'Simplifying the commercial lending process',
    // subtitle: 'Simplyfying the commercial lending process',
    alignment: 'bottom',
    keywords: ['Product Design', 'Identity Design', 'Marketing Website Design', 'Research', 'Product Development'],
    description: [
      'CRE Simple’s platform, OneSource, simplifies the lending process for commercial real estate deals under $5 million. CRE had the ability to develop the underlying workflow management capability, but needed CoMedia to help with the visual/user experience design and front-end development.',
    ],
    product: [
      'Every commercial real estate lending deal, whether it is a purchase of a corner store or a high-rise building, is similarly complex. CRE Simple wanted to create a completely digital and templatized lending platform that was streamlined and friction-free for capital partners, brokers, and borrowers.',
      'CRE Simple had the expertise in the lending market and backend processing, but needed CoMedia to help define a smooth user experience that only included the essential tasks from a traditionally complicated lending process.',
    ],
    process: [
      'CRE Simple’s goal was to successfully run multiple deals through the platform to demonstrate that traditionally complicated loan deals could be done using a friendly user interface. Running deals through the system would prove to capital partners that the platform could reduce the amount of time to close a deal from 90 days to 60 days.',
      'Due to the complicated nature of commercial real estate loans, we had to learn the details of commercial real estate lending. We did that through research and interviews with key stakeholders at a leading commercial lending company. From there, we continued to iterate and test to ensure that the solution met the expectations of the end users.',
    ],
    conclusion: [
      'We designed and built the front-end of the OneSource platform that could successfully run traditionally complicated loan deals in a friendly user interface.',
      'The quote process enabled near instantaneous quote generation thus allowing the brokers to create quotes in seconds. CRE Simple was then able to run live deals to prove the model. With that success, CRE Simple was able to close their next round of investment to continue product development and build up their sales pipeline.',
    ],
  },
  // 'pogo': {
  //   name: 'Pogo Energy',
  //   slug: 'pogo',
  //   title: 'Pay-as you go electricity mobile web app',
  //   // subtitle: 'Simplifying commercial real estate lending process',
  //   alignment: 'bottom',
  //   keywords: ['Product Design', 'Identity Design', 'Marketing Website Design', 'Research', 'Product Development'],
  //   description: [
  //     'Looking to reinvigorate and differentiate its market, Hightail developed Hightail Spaces to build upon its success as the first digital file delivery service. Despite its talented team, there is a capacity limit to any team. Hightail looked to us to help on product/design strategy and augment its team.',
  //   ],
  // },
  'soundable': {
    name: 'Soundable Health',
    slug: 'soundable',
    title: 'Wellness app for early detection',
    // subtitle: 'Wellness app for early detection',
    alignment: 'bottom',
    keywords: ['Product Design', 'Identity Design', 'Marketing Website Design', 'Research', 'Product Development'],
    description: [
      'Soundable Health is an innovative healthcare startup based in Seoul, South Korea. Their team of researchers, doctors, data scientists, and engineers developed audio-analyzing technology for early disease detection and prevention.',
      'They approached CoMedia to design their first mobile app, PRIVY Urine Flow Monitor, as well as develop an extendable brand to grow with their future suite of products.',
    ],
    product: [
      'Monitoring urinary health is important for many doctors and patients. The resulting data can provide early indicators of many life-changing but preventable diseases. Right now, the clinical solution involves patients timing their bathroom trips, measuring urine volume, and recording results every time they void. This process is cumbersome, unpleasant, and error-prone.',
      'Soundable’s technology uses the microphones in mobile devices to analyze the sounds of a user’s urine flow. Users can track their frequency, flow volume, flow rate, as well as averages over time. PRIVY has been able to produce the same data with the convenience of just using a mobile phone, and all a patient has to do is tap “START.”',
    ],
    process: [
      'It was important for our team never to lose sight of the client’s long-term goal: helping patients and doctors more easily gather usable data for early detection of preventable disease.',
      'Therefore, our designers’ primary goals for PRIVY were to make the recording process as simple as possible and not overwhelm the user with too much unnecessary clutter or data. After some early designs, internal discussion, and talking to users, it became clear that the main action of the app, recording audio, had to be available from anywhere in the app and feel as seamless as possible. We stripped our early iterations down to the essentials, highlighting the primary action in the always-accessible bottom nav bar.',
    ],
    branding: [
      'Soundable came to CoMedia with some early concepts for their brand. We knew that the Soundable had grand plans for the future, so we wanted to build something lightweight that a future Soundable designer could easily work with and build upon.',
      'Our designers decided to take more cues from the calming, patient-centric wellness/prevention direction rather than more clinical, medical brands. Starting with a wellness app, Soundable’s product line will span the range of personal health maintenance including FDA-approved healthcare applications.',
      'While iterating on the perhaps too-obvious audio angle, we ended up finding our solution directly in the data. A Soundable engineer sent us sample soundwaves to use as we designed the PRIVY interface and the logo jumped right off the screen for us.',
      'Rotating the soundwave on its axis, we saw the unique series of coaxial circles, which we shaped into the Soundable’s brand. The flower and open arms of the Soundable logo welcome users to be at peace with their prevention application rather than brace themselves for an onslaught of endless data and red graphs.',
      'The brand focus was simplicity and balance that is functional across mediums and can easily grow with the company.',
    ],
    conclusion: [
      'CoMedia developed Soundable’s brand identity and helped them launch their first app, PRIVY, in Korea as well as the United States. The clean, undemanding interface allows users to start recording with a single tap and track their activity over time. ',
      'PRIVY proves Soundable’s technology to users, investors, and the medical community. With CoMedia’s design work to back them up, Soundable was recently admitted to a well-known Bay Area accelerator where they will continue to grow. CoMedia has an ongoing relationship with Soundable, and we’re thrilled to see what they do next.',
    ],
  },
}

export const vodafone = projects.vodafone
export const soundable = projects.soundable
export const cre = projects.cre
export const nec = projects.nec

export const about = {
  do: [
    {
      name: 'product management',
      description: 'We collaborate with you to define the overall goals for the product and then break down the project into short, achievable sprints with well-defined deliverables. We make sure the project stays on track and meets expectations.',
    },
    {
      name: 'design',
      description: 'From whiteboard and pencil/paper sketches to final visual designs, our goal is to achieve the best possible result for your product. We focus on user experience and user interaction to create an intuitive and beautiful interface.',
    },
    {
      name: 'development',
      description: 'Our engineering team is experienced and flexible enough to work with a variety of methodologies to best fit our client’s needs. We are experienced in working with existing code as well as building new products into production-quality applications.',
    },
    {
      name: 'our team and process',
      description: 'We enjoy building products and have fun while doing it. We are a diverse team with different strengths and skills working to create products that customers will enjoy. We ensure all teams are involved in the design and development process and work closely with our client to meet requirements.',
    },
  ],
  are: [
    {
      name: 'Chris Basey',
      function: 'Co-Founder and Director of User Experience',
      description: 'Chris has been working as a UX/UI Designer for over a decade. He has worked with various innovation teams with companies such as Vodafone, NEC, and VMware.',
    },
    {
      name: 'Ken Yang',
      function: 'Co-Founder and Director of Product Management',
      description: 'Ken is a 20 year veteran of Silicon Valley. He has been a founding team member at several startups, Director of Product at Hightail, and consulted for Microsoft on Surface for hardware customization.',
    },
    {
      name: 'Ben Oertel',
      function: 'Lead Engineer',
      description: 'Ben has years of experience working as a software engineer. He is proficient with both backend (such as Python and Node.js) and frontend (HTML/CSS and Javascript with React or Angular frameworks) technologies for web and mobile.',
    },
    {
      name: 'Jim Ramsey',
      function: 'Lead Designer',
      description: 'Jim has more than a decade of experience designing software for consumer and enterprise users. He specializes in designing intuitive solutions to complex problems.',
    },
    {
      name: 'Vicki Chiang',
      function: 'Lead Researcher',
      description: 'Vicki has an extensive background in research and enjoys understanding users to help create optimal product experiences. Working with a range of clients to uncover insights from their users, her goal is to deliver results to shape and improve products. Vicki holds a Masters Degree in Human Computer Interaction, a BA in English and a BS in Biology.',
    },
  ]
}

export const jobs = [
  {
    position: "Fullstack Engineer",
    level: "Mid/Senior Level",
    details: {
      Responsibilities: [
        "Build prototype to production-ready web applications",
	"Participate in the full software development life-cycle",
	"Work with PMs and designers to define requirements and scope",
	"Interact with clients and team members to build great solutions",
	"Testing and debugging applications",
	"Ability to switch between projects"
      ],
      Requirements: [
        "Proficient in frontend (React, Javascript, CSS) and backend (Python, Node.js or others) technologies",
	"Knowledge in infrastructure technologies such as databases, hosting and monitoring",
        "A passion for creating a great user experience"
      ],
      Location: [
        "San Francisco - USA",
        "Vancouver - Canada"
      ]
    }
  }
]
