
import screens1 from './screens-1.png'
import screens2 from './screens-2.png'
import screens3 from './screens-3.png'
import screens4 from './screens-4.png'

import group1 from './group-1.png'
import group2 from './group-2.png'
import group3 from './group-3.png'
import group4 from './group-4.jpg'
import group5 from './group-5.jpg'
import group6 from './group-6.jpg'

import propertiesDetail from './properties-detail.png'
import propertiesList from './properties-list.png'

export const group = [
  group1,
  group2,
  group3,
  group4,
  group5,
  group6,
]

export {
  propertiesDetail,
  propertiesList,
}

export const screens = [
  screens1,
  screens4,
  screens3,
  screens2,
]
