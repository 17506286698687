import React from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import NavLinks from '../NavLinks'
import { projects } from '../../utils/data'

const HomeLeft = ({ closed, setClosed, history, active, slugs, onMouseEnter }) => {

  const onClick = (slug) => () => {
    setClosed(true)
    setTimeout(() => {
      history.push(`/${slug}`)
    }, 500)
  }

  const leftClass = classNames({
    'left-half-overflow-handler': true,
    'closed': closed,
  })

  return (
    <div className={leftClass}>
      <div className='home-left-content'>
        <h1><span>CoMedia</span> designs, develops and innovates for companies large and not yet large</h1>
        <ul>
          {slugs.map(slug => {
            const project = projects[slug];
            return (
            <li key={slug} className={slug === active ? 'active' : null}
              onMouseEnter={onMouseEnter(slug)}
              onClick={onClick(slug)} >
              <h2>{project.name}</h2>
              <p>{project.title}</p>
            </li>
            )
          })}
          <a href='/work' className='all-projects'>
            all projects
          </a>
        </ul>
        <div>
          <NavLinks className='home-nav' />
          <p className='footnote'>&copy; 2021 CoMedia Design Inc.</p>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HomeLeft)
