import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import { about } from '../../utils/data'
import sanfran from '../../images/sanfran.png'
import vancouver from '../../images/vancouver.png'
import Footer from '../Footer'

const About = () => {
  return (
    <Fragment>
      <Helmet>
        <title>CoMedia | About</title>
      </Helmet>
      <div className='about'>
        <div className='compress'>
          <div className='title'>
            <h1>We deliver results. Beautiful, usable, delightful results.</h1>
            {/*<p>We create seamless and thoughtful user experience with precise attention to detail</p>*/}
          </div>
        </div>
        <div className='section compress'>
          <div className='about-wrapper'>
            <div className="list-title-container">
              <h1>WHAT WE DO</h1>  
            </div>
            <ul>
              {about.do.map((item, index) => (
                <li key={index}>
                  <h2 className='bot-margin'>{item.name}</h2>
                  <p>{item.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <ul className='section offices-wrapper compress'>
          <li>
            <div className='office-image'
              style={{ backgroundImage: `url(${sanfran})`}}>
            </div>
            <h2>CoMedia San Francisco</h2>
            <p>149 Natoma Street</p>
            <p>San Francisco CA 94105</p>
          </li>
          <li>
            <div className='office-image'
              style={{ backgroundImage: `url(${vancouver})`}}>
            </div>
            <h2>CoMedia Vancouver</h2>
            <p>#300 - 1090 Homer Street</p>
            <p>Vancouver, BC Canada</p>
          </li>
        </ul>
        <div className='section compress'>
          <div className='about-wrapper'>
            <div className="list-title-container">
              <h1>PRINCIPALS</h1>  
            </div>
            <ul>
              {about.are.map((item, index) => (
                <li key={index}>
                  <h2>{item.name}</h2>
                  <h3>{item.function}</h3>
                  <p>{item.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default About
