import React from 'react'
import { Link } from 'react-router-dom'

const pages = ['work', 'about', 'contact', 'careers']

const NavLinks = ({ className }) => (
  <ul className={className || null}>
    {pages.map(page => (
      <Link key={page} to={`/${page}`} >{page}</Link>
    ))}
  </ul>
)

export default NavLinks
