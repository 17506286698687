import React from 'react'

import BasicTitleText from './components/BasicTitleText'
// import DoubleImage from './components/DoubleImage'
import { soundable } from '../../utils/data'
import  {
  soundableDemo1,
  soundableDemo2,
  soundableDesign,
  soundableDesignMain,
  soundableDesignSmall1,
  soundableDesignSmall2,
  // soundableResearch,
} from '../../images/soundable'

const Soundable = () => (

  <div className='study-background'>

    <BasicTitleText title='product' textArray={soundable.product} />

    <div className='soundable-demo'>
      <div className='compress'>
        <div><img src={soundableDemo1} alt='Soundable Demo' /></div>
        <div><img src={soundableDemo2} alt='Soundable Do and Donts' /></div>
      </div>
    </div>

    <BasicTitleText title='process' textArray={soundable.process} />

    <div className='soundable-screen-designs'
      style={{ backgroundImage: `url(${soundableDesign})` }}
    />

    <BasicTitleText title='branding' textArray={soundable.branding} />

    <div className='soundable-design compress'>
      <div className='main'>
        <div className='image'
          style={{ backgroundImage: `url(${soundableDesignMain})`}} >
        </div>
      </div>
      <div className='extra-wrapper'>
        <div className='extra'>
          <div className='image'
            style={{ backgroundImage: `url(${soundableDesignSmall1})`}} >
          </div>
        </div>
        <div className='extra'>
          <div className='image'
            style={{ backgroundImage: `url(${soundableDesignSmall2})`}} >
          </div>
        </div>
      </div>
    </div>

    <BasicTitleText title='conclusion' textArray={soundable.conclusion} />

  </div>
)

export default Soundable
