import React from 'react'
import { Helmet } from 'react-helmet'

import ContactForm from './ContactForm'
import NavLinks from '../NavLinks'

const Contact = () => (
  <div className='contact'>
    <Helmet>
      <title>CoMedia | Contact</title>
    </Helmet>
    <div className='compress'>
      <div className='title'>
        <h1>Where we are</h1>
        <h2>
          <span>149 Natoma Street</span>
          <span>San Francisco CA</span>
          <span>94105</span>
        </h2>
        <h2>info@comediadesign.com</h2>
      </div>
      <div className='section form'>
        <h1>Drop us a line</h1>
        <ContactForm />
      </div>
      <div className='navbar-footnote white-background'>
        <NavLinks />
        <p>&copy; 2021 CoMedia Design Inc.</p>
      </div>
    </div>
  </div>
)

export default Contact
