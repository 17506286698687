
import axios from 'axios'

const domain = 'https://comediadesign-mail-server.herokuapp.com';

export const sendEmail = data => (
  axios.request({
    url: domain + '/email',
    method: 'post',
    contentType: 'application/json',
    responseType:'json',
    data: data
  })
)
