import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router'

import MoreProjects from '../MoreProjects'
import Footer from '../Footer'

import MyComms from './MyComms'
import Soundable from './Soundable'
import CRESimple from './CRESimple'
import NECGuestViewGuide from './NECGuestViewGuide'
// import PogoEnergy from './PogoEnergy'

const MAPPING = {
  vodafone: MyComms,
  cre: CRESimple,
  nec: NECGuestViewGuide,
  soundable: Soundable,
}

class StudyContent extends Component {

  render() {
    const { slug } = this.props;

    const CaseStudyComponent = MAPPING[slug];

    if (!CaseStudyComponent) {
      return <Redirect to='/' />
    }

    return (
      <Fragment>
        <div className='study'>

          <CaseStudyComponent />

          <div className='study-more-projects'>
            <div className='compress'>
              <h1>Browse more projects</h1>
            </div>
            <MoreProjects slug={slug} />
          </div>
        </div>
        <Footer />

      </Fragment>
    )
  }
}

export default StudyContent
