import "@babel/polyfill";
import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import ScrollToTop from './ScrollToTop'
import NavBar from './NavBar'
import Controller from './home/Controller'
import Work from './pages/Work'
import About from './pages/About'
import Contact from './pages/Contact'
import Careers from './pages/Careers'

class App extends Component {

  state = {
    closed: false,
  }

  setClosed = bool => {
    this.setState({ closed: bool })
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.setClosed(false)
    }
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <NavBar setClosed={this.setClosed} />
          <Switch>
            <Route path='/work' exact component={Work} />
            <Route path='/about' exact component={About} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/careers' exact component={Careers} />

            <Redirect from='/index.html' exact to='/' />
            <Redirect from='/work.html' exact to='/work' />
            <Redirect from='/about_contact.html' exact to='/about' />
            <Redirect from='/about_contact.html#lets-chat-banner-content' exact to='/contact' />
            <Redirect from='/vodafone_case.html' exact to='/vodafone' />

            <Route path='/:slug' render={props => (
              <Controller {...props}
                closed={this.state.closed}
                setClosed={this.setClosed}
              />
            )} />
            <Route path='/' render={props => (
              <Controller {...props}
                closed={this.state.closed}
                setClosed={this.setClosed}
              />
            )} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App
