import React from 'react'
import classNames from 'classnames'

import { backgroundImages } from '../../images/home'

const ProductBackground = ({ slug, active, gradient }) => {
  const style = {
    backgroundImage: `url(${backgroundImages[slug]})`,
  }
  const backgroundClass = classNames({
    'project-container': true,
    active,
    zoomed: gradient,
  })
  return (
    <li className={backgroundClass} style={style}>
      {gradient && <div className='gradient'></div> }
    </li>
  )
}

export default ProductBackground
