import React from 'react'
import classNames from 'classnames'

import { projects } from '../../utils/data'
import { productImages } from '../../images/home'

const ProductPositioner = ({ slug, active }) => {
  const project = projects[slug];
  const style = {
    backgroundImage: `url(${productImages[slug]})`,
  }
  const productClass = classNames({
    'product-positioner': true,
    [project.alignment]: true,
    active,
  })
  return (
    <li className={productClass}>
      <div className="product-image" style={style} />
    </li>
  );
}

export default ProductPositioner
