
import vodafoneBranding1 from './vodafone-branding-1.png'
import vodafoneBranding2 from './vodafone-branding-2.png'
import vodafoneSketch from './vodafone-sketch.png'
import vodafoneWhiteboard from './vodafone-whiteboard.png'
import vodafoneCarousel from './carousel.png'

import background from './berlin.png'
import frame from './iphone-template.png'
import phone1 from './my-comms-app-screenshot-1.png'
import phone2 from './my-comms-app-screenshot-2.png'
import phone3 from './my-comms-app-screenshot-3.png'
import phone4 from './my-comms-app-screenshot-4.png'
import phone5 from './my-comms-app-screenshot-5.png'

export {
  vodafoneBranding1,
  vodafoneBranding2,
  vodafoneSketch,
  vodafoneWhiteboard,
  vodafoneCarousel,
}

export const carousel = {
  background,
  frame,
  phones: [
    phone1,
    phone2,
    phone3,
    phone4,
    phone5,
  ],
}
