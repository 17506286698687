
import React, { Component, Fragment } from 'react'
import Lightbox from 'react-images'

import { designs } from '../../../images/nec/designs'

class LightboxNEC extends Component {

  state = {
    lightboxIsOpen: false,
    currentImage: 0,
  }

  handleClick = index => {
    this.setState({
      lightboxIsOpen: true,
      currentImage: index,
    })
  }

  render() {
    const { lightboxIsOpen, currentImage } = this.state
    return (
      <Fragment>
        <ul className='section nec-design compress'>
          {designs.map((image, index) => (
            <img key={index} src={image.src}
              alt={image.alt || `NEC GuestView Guide design ${index + 1}`}
              onClick={() => this.handleClick(index)}
            />
          ))}
        </ul>
        <Lightbox
          images={designs}
          isOpen={lightboxIsOpen}
          currentImage={currentImage}
          onClickPrev={() => this.setState({ currentImage: currentImage - 1 })}
          onClickNext={() => this.setState({ currentImage: currentImage + 1 })}
          onClose={() => this.setState({ lightboxIsOpen: false })}
          snipperColor={'#E6007A'}
          backdropClosesModal={true}
        />
      </Fragment>
    );
  }
}

export default LightboxNEC
