import React from 'react'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { carousel } from '../../../images/vodafone'

const PhoneCarousel = () => {
  const settings = {
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 3,
    infinite: true,
    centerMode: true,
    className: 'carousel',
    responsive: [
      {
        breakpoint: 911,
        settings: {
          slidesToShow: 1,
        }
      },
    ],
  }

  const background = { backgroundImage: `url(${carousel.background})` }

  return (
    <div className='voda-phone-carousel compress' style={background}>
      <div className='blue-opacity-layer'></div>
      <div className='frame'>
        <img src={carousel.frame} alt='MyComms design frame' />
      </div>
      <Slider {...settings}>
        {carousel.phones.map((photo, index) => (
          <div key={index} className='item'>
            <img src={photo}
              alt={`MyComms design screen ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default PhoneCarousel
