import React, { useState, Fragment } from 'react';
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import HomeLeft from './HomeLeft'
import StudyLeft from './StudyLeft'
import StudyContent from '../studies/StudyContent'
import ProductPositioner from './ProductPositioner'
import ProductBackground from './ProductBackground'
import { projects } from '../../utils/data'


const Controller = ({ closed, setClosed, match: { params } }) => {
  let { slug } = params;
  const slugs = Object.keys(projects)
  if (!slugs.includes(slug)) {
    slug = null
  }

  const [active, setActive] = useState(slug || slugs[0]);
  const onMouseEnter = (slug) => () => !closed && setActive(slug);

  const headerClass = classNames({
    'header': true,
    'on-home-page': !slug,
    'on-study-page': slug,
  })

  return (
    <Fragment>
       <Helmet>
        <title>CoMedia</title>
      </Helmet>
      <div className={headerClass} >
        <ul className="projects-handler">
          {slugs.map(s =>
            <ProductBackground key={s} slug={s} active={s === active}
              gradient={closed || slug}
            />
          )}
        </ul>
        <div className="left-half">
          { slug
            ? <StudyLeft slug={slug} />
            : <HomeLeft
                active={active}
                slugs={slugs}
                onMouseEnter={onMouseEnter}
                closed={closed}
                setClosed={setClosed}
              />
          }
        </div>
        <div className="right-half">
          <ul className="products-handler">
            {slugs.map(s =>
              <ProductPositioner key={s} slug={s} active={s === active} />
            )}
          </ul>
        </div>
      </div>
      { slug && <StudyContent slug={slug} />}
    </Fragment>
  );
}

export default Controller;
