import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import MoreProjects from '../MoreProjects'
import Footer from '../Footer'

import { logos } from '../../logos'

const Work = () => {
  return (
    <Fragment>
      <Helmet>
        <title>CoMedia | Work</title>
      </Helmet>
      <div className='work'>
        <div className='compress'>
          <div className='title'>
            <h1>Working together to create great products</h1>
          </div>
          <div className='section company-logos'>
            {logos.map((Logo, index) => (
              <Logo key={index} />
            ))}
          </div>
        </div>
        <div className='more-projects'>
          <div className='compress'>
            <h1>Featured case studies</h1>
          </div>
          <MoreProjects />
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Work
