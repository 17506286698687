import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import { Comedia } from '../logos'

const pages = ['work', 'about', 'contact', 'careers']

class NavBar extends Component {

  state = {
    menuOpen: false,
  }

  toggleHamburger = () => {
    const { menuOpen } = this.state
    this.setState({ menuOpen: !menuOpen })
  }

  render() {
    const { setClosed } = this.props
    const { menuOpen } = this.state
    const dropdownClass = classNames({
      'dropdown-menu': true,
      'opened': menuOpen,
    })
    const hamburgerClass = classNames({
      'hamburger-menu': true,
      'animate': menuOpen,
    })

    return (
      <div className='navbar-full-width'>
        <div className='compress navbar-content'>
          <Link to='/' onClick={() => setClosed(false)}>
            <Comedia />
          </Link>
          {/*{pages.includes(pathname.slice(1)) &&
            <ul>
              {pages.map(i => (
                <li key={i}>
                  <NavLink to={`/${i}`} activeClassName='active'>{i}</NavLink>
                </li>
              ))}
            </ul>
          }*/}
          <div className='menu-wrapper' onClick={() => this.toggleHamburger()} >
            <div className={hamburgerClass}></div>
          </div>
        </div>
        <div className={dropdownClass}>
          <ul className='compress'>
            {pages.map(i => (
              <li key={i}>
                <NavLink
                  to={`/${i}`}
                  onClick={() => this.setState({ menuOpen: false })}
                  activeClassName='active'
                >
                  {i}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default withRouter(NavBar)
