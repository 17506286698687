import { ReactComponent as DySi } from './_DYSI.svg';
import { ReactComponent as CATech } from './_CA_TECH.svg';
import { ReactComponent as Ericsson } from './_ERICSSON.svg';
import { ReactComponent as Hightail } from './_HIGHTAIL.svg';
import { ReactComponent as Kodak } from './_KODAK.svg';
import { ReactComponent as MoW } from './_MEALSWHEELS.svg';
import { ReactComponent as NEC } from './_NEC.svg';
import { ReactComponent as Postmates } from './_POSTMATES.svg';
import { ReactComponent as Proofpoint } from './_PROOFPOINT.svg';
import { ReactComponent as TDAmeritrade } from './_TD_AMERITRADE.svg';
import { ReactComponent as VMware } from './_VMWARE.svg';
import { ReactComponent as Vodafone } from './_VODAFONE.svg';

import { ReactComponent as Comedia } from './comedia.svg';

export { Comedia }

export const logos = [
  CATech,
  DySi,
  Ericsson,
  Hightail,
  Kodak,
  MoW,
  NEC,
  Postmates,
  Proofpoint,
  TDAmeritrade,
  VMware,
  Vodafone,
]
