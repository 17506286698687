import React from 'react'

import BasicTitleText from './components/BasicTitleText'
import SlickCarousel from './components/SlickCarousel'
import LightboxNEC from './components/LightboxNEC'
import { nec } from '../../utils/data'
import {
  screens,
  // designs2,
  group,
  propertiesDetail,
  propertiesList,
} from '../../images/nec'

const NECGuestViewGuide = () => (

  <div className='section'>

    <BasicTitleText title='product' textArray={nec.product} />

    <div className='nec-quad-image'>
      <ul className='compress'>
        {screens.map((image, index) => (
          <li key={index} >
            <div style={{ backgroundImage: `url(${image})` }} ></div>
          </li>
        ))}
      </ul>
    </div>

    <BasicTitleText title='our process' textArray={nec.process} />

    <SlickCarousel photoArray={group} />

    {/*<ul className='nec-group'>
      {group.map((image, index) => (
        <li key={index} className='item'>
          <div className='image'
            style={{ backgroundImage: `url(${image})` }} >
          </div>
        </li>
      ))}
    </ul>*/}

    <BasicTitleText textArray={nec.process2} />

    <div className='cre-process'>
      <div className='compress'>
        <div className='item'>
          <div className='image'
            style={{ backgroundImage: `url(${propertiesDetail})`}} >
          </div>
        </div>
        <div className='item'>
          <div className='image'
            style={{ backgroundImage: `url(${propertiesList})`}} >
          </div>
        </div>
      </div>
    </div>

    <BasicTitleText title='conclusion' textArray={nec.conclusion} />

    {/*<ul className='section nec-design compress'>
      {designs2.map((image, index) => (
        <li key={index} >
          <div style={{ backgroundImage: `url(${image})` }} ></div>
        </li>
      ))}
    </ul>*/}

    <LightboxNEC />

  </div>
)

export default NECGuestViewGuide
