
import active1 from './active-01.jpg'
import active2 from './active-02.jpg'
import active3 from './active-03.jpg'
import active4 from './active-04.jpg'

import arrival1 from './arrival-01.jpg'
import arrival2 from './arrival-02.jpg'
import arrival3 from './arrival-03.jpg'
import arrival4 from './arrival-04.jpg'

import infoDiscovery1 from './info-discovery-01.jpg'
import infoDiscovery2 from './info-discovery-02.jpg'
import infoDiscovery3 from './info-discovery-03.jpg'
import infoDiscovery4 from './info-discovery-04.jpg'

import payments1 from './payments-01.png'
import payments2 from './payments-02.png'
import payments3 from './payments-03.png'
import payments4 from './payments-04.png'

import propertyInfo1 from './property-info-01.jpg'
import propertyInfo2 from './property-info-02.jpg'
import propertyInfo3 from './property-info-03.jpg'
import propertyInfo4 from './property-info-04.jpg'

export const designsOld = [
  active1, active2, active3, active4,
  arrival1, arrival2, arrival3, arrival4,
  infoDiscovery1, infoDiscovery2, infoDiscovery3, infoDiscovery4,
  payments1, payments2, payments3, payments4,
  propertyInfo1, propertyInfo2, propertyInfo3, propertyInfo4,
]

export const designs = [
  { src: arrival1 },
  { src: arrival2 },
  { src: arrival3 },
  { src: arrival4 },
  { src: infoDiscovery1 },
  { src: infoDiscovery2 },
  { src: infoDiscovery3 },
  { src: infoDiscovery4 },
  { src: propertyInfo1 },
  { src: propertyInfo2 },
  { src: propertyInfo3 },
  { src: propertyInfo4 },
]
