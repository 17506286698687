
// products
import deviceCRE from './hero-CRE-device.png'
import deviceMyComms from './hero-MyComms-device.png'
import deviceNEC from './hero-NEC-device.png'
import devicePogo from './hero-POGO-device.png'
import deviceSoundable from './hero-SOUNDABLE-device.png'

// backgrounds
import bgCRE from './hero-CRE-bg.png'
import bgMyComms from './hero-MyComms-bg.png'
import bgNEC from './hero-NEC-bg.png'
import bgPogo from './hero-POGO-bg.png'
import bgSoundable from './hero-SOUNDABLE-bg.png'

export const productImages = {
  cre: deviceCRE,
  vodafone: deviceMyComms,
  nec: deviceNEC,
  pogo: devicePogo,
  soundable: deviceSoundable,
}

export const backgroundImages = {
  cre: bgCRE,
  vodafone: bgMyComms,
  nec: bgNEC,
  pogo: bgPogo,
  soundable: bgSoundable,
}
