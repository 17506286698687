import React from 'react'
import { Formik, Field, Form } from 'formik'

// import ExpandableField from './ExpandableField'
import { sendEmail } from '../../utils/email'

// import { isValidEmail } from '../utils/helpers'
//
// const validator = values => {
//   let errors = {}
//
//   if (!values.email) {
//     errors.email = "cannot be empty"
//   } else if (!isValidEmail(values.email)) {
//     errors.email = "invalid email"
//   }
//
//   if (!values.name) {
//     errors.password = "cannot be empty"
//   }
//
//   return errors
// }

const ContactForm = () => (
  <Formik
    // validate={validator}
    initialValues={{
      name: '',
      email: '',
      message: '',
    }}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      const data = {
        ...values, 
        from: 'contact@comediadesign.com',
        subject: 'Comedia Website Contact Form'
      }
      sendEmail(data)
        .then(response => {
          actions.setStatus({ send: true })
          actions.setSubmitting(false);
          setTimeout(() => {
            actions.resetForm()
          }, 2000)
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ error: true })
        })
    }}
    render={({ values, status, isSubmitting }) => (
      <Form>
        <Field type='text' name='name' placeholder='NAME' />
        <Field type='email' name='email' placeholder='EMAIL' />
        <Field type='text' name='message' placeholder='MESSAGE' />
        {isSubmitting && <p className='black'>SENDING</p>}
        {status && status.send && <p>YOUR MESSAGE HAS BEEN SENT</p>}
        {status && status.error && <p>AN ERROR OCCURRED</p>}
        {!status && !isSubmitting &&
          <button type='submit' disabled={!values.name || !values.email}>
            SEND
          </button>
        }
      </Form>
    )}
  />
)

export default ContactForm
