import React from 'react'
import classNames from 'classnames'

import { projects } from '../utils/data'
import { backgroundImages } from '../images/home'
import { smallProducts } from '../images/projects'

const MoreProjects = ({ slug }) => {
  const keys = Object.keys(projects)
  const projectsClass = classNames({
    'more-projects-wrapper': true,
    'compress': true,
    'on-work-page': slug ? false : true,
    'on-study-page': slug ? true : false,
  })

  return (
    <div className={projectsClass}>
      {keys.map((key, index) => {
        const project = projects[key]
        if (key === slug) {
          return null
        } else {
          const alignmentClass = classNames({ [project.alignment]: true })
          return (
            <a key={key} className='item' href={key}>
              <div className='overflow-handler'>
                <div
                  className='background-image'
                  style={{ backgroundImage: `url(${backgroundImages[key]})` }}
                ></div>
                <img
                  src={smallProducts[key]}
                  alt={project.name}
                  className={alignmentClass}
                />
              </div>
              <h2>{project.name}</h2>
              <p>{project.title}</p>
            </a>
          )
        }
      })}
    </div>
  )
}

export default MoreProjects
