
import cre from './hero-CRE-device-small.png'
import nec from './hero-NEC-device-small.png'
import myComms from './hero-MyComms-device-small.png'
import soundable from './hero-Soundable-device-small.png'

export const smallProducts = {
  cre,
  nec,
  vodafone: myComms,
  soundable,
}
