import React, { Fragment } from 'react'

const JobPosting = ({job: {position, level, details}}) => {

  const handleClick = () => {
    const applyPosition = document.getElementById("apply-form")
    applyPosition.scrollIntoView({
      behavior: "smooth",
    });
  }
  
  return (
    <div className="job-posting-wrapper">
      <div className="list-title-container">
        <h1 className="uppercase">{position}</h1>
        <h2 className="uppercase">({level})</h2>
      </div>
      <ul>
        {Object.keys(details).map( (detailKey, index) => {
          return (
            <li key={index}>
              <h2 className='bot-margin'>{detailKey}</h2>
              <ul className="bullet-point-list">
                {details[detailKey].map( (detailItem, detailIndex) => (
                  <li key={detailIndex} className="job-posting-detail-item bullet">{detailItem}</li>
                ))}
              </ul>
            </li>
          )
        })}
        <button className="apply-button" onClick={handleClick}>
          > APPLY
        </button>
      </ul>
    </div>
  )
}

export default JobPosting