import React, { Component } from 'react'
import classNames from 'classnames'
import smoothscroll from 'smoothscroll-polyfill';

import { ArrowDown } from '../../icons'
import { projects } from '../../utils/data'

smoothscroll.polyfill();

class StudyLeft extends Component {

  state = {
    startAnim: false,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ startAnim: true })
    }, 100)
  }

  handleClick() {
    window.scroll({
      top: .7 * window.innerHeight,
      left: 0,
      behavior: 'smooth',
    })
  }

  render() {
    const { slug } = this.props
    const { startAnim } = this.state
    const project = projects[slug]
    const contentClass = classNames({
      'study-left-content': true,
      'revealed': startAnim,
    })
    return (
      <div className={contentClass}>
        <h1>{project.name}</h1>
        <h2>{project.title}</h2>
        <div className='paragraph-wrapper'>
          {project.description &&
            project.description.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        <button className='arrow-bouncing' onClick={this.handleClick}>
          <ArrowDown />
        </button>
      </div>
    )
  }
}

export default StudyLeft
