import React from 'react'

import BasicTitleText from './components/BasicTitleText'
import DoubleImage from './components/DoubleImage'
import PhoneCarousel from './components/PhoneCarousel'
import { vodafone } from '../../utils/data'
import {
  vodafoneBranding1,
  vodafoneBranding2,
  vodafoneSketch,
  vodafoneWhiteboard,
  // vodafoneCarousel,
} from '../../images/vodafone'

const MyComms = () => (

  <div className='section'>

    <BasicTitleText title='product' textArray={vodafone.product} />

    {/*<div className='compress' style={{ paddingLeft: '0', paddingRight: '0'}}>
      <img
        style={{ width: '100%' }}
        src={vodafoneCarousel}
        alt='MyComms design'
      />
    </div>*/}
    <PhoneCarousel />

    <BasicTitleText title='process' textArray={vodafone.process} />

    <DoubleImage imageOne={vodafoneWhiteboard} imageTwo={vodafoneSketch} />

    <BasicTitleText title='branding & visuals' textArray={vodafone.branding} />

    <DoubleImage imageOne={vodafoneBranding1} imageTwo={vodafoneBranding2} />

    {/*<BasicTitleText title='branding & visuals' textArray={vodafone.visuals} />*/}

  </div>
)

export default MyComms
