import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import { about, jobs } from '../../utils/data'
import sanfran from '../../images/sanfran.png'
import vancouver from '../../images/vancouver.png'
import Footer from '../Footer'
import JobPosting from '../careers/JobPosting';
import ApplyForm from './ApplyForm'

const About = () => {
  return (
    <Fragment>
      <Helmet>
        <title>CoMedia | Careers</title>
      </Helmet>
      <div className='about careers'>
        <div className='compress'>
          <div className='title'>
            <h1>Come join our team!</h1>
            <p>CoMedia Design is a small product innovation agency, working with very early stage startups and large Enterprise clients like Ericsson, VMware, NEC and Macy’s.</p>
          </div>
        </div>
        <div className='section compress'>
          <div className='about-wrapper jobs-wrapper'>
            {jobs.map( (job, index) => (
              <JobPosting key={index} job={job} /> 
            ))}
          </div>
        </div>
        <div className='section form compress'>
          <div id="apply-form" className="apply-wrapper">
            <h1>Apply</h1>
            <ApplyForm />
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default About
