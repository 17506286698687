import React from 'react'

const DoubleImage = ({ imageOne, imageTwo }) => (
  <div className='double-image compress'>
    <div style={{ backgroundImage: `url(${imageOne})`}}></div>
    <div style={{ backgroundImage: `url(${imageTwo})`}}></div>
  </div>
)

export default DoubleImage
