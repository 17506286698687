import React from 'react'
import { Link } from 'react-router-dom'

import NavLinks from './NavLinks'

const Footer = () => (
  <div className='footer'>
    {/*<div className='height-handler'></div>*/}
    <div className='content'>
      <div className='compress'>
        <div className='chat'>
          <Link to='contact'>
            <h1>Let's chat</h1>
            <p>We would love to help you</p>
          </Link>
        </div>
        <div className='navbar-footnote'>
          <NavLinks />
          <p>&copy; 2021 CoMedia Design Inc.</p>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
