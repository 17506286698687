import React from 'react'

const Icon = ({ size, viewBox, fill, stroke, ...rest }) => {
  let style = {}
  if (fill) {
    style.fill = 'currentcolor'
  }
  if (stroke) {
    style.path = 'currentcolor'
  }
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={viewBox}
      style={style}
      {...rest}
    />
  );
}

Icon.defaultProps = {
  size: 32,
  viewBox: '0 0 32 32',
};

export default Icon;