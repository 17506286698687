import React from 'react'

const BasicTitleText = ({ title, titleArray, textArray }) => (
  <div className='study-basic-title-text compress'>
    <div className='wrapper'>
      {title && <h1>{title}</h1>}
      {titleArray &&
        <h1>
          {titleArray.map((part, index) => (
            <span key={index}>{part}</span>
          ))}
        </h1>
      }
      {!title && !titleArray && <h1> </h1>}
      <div className='content'>
        {textArray.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  </div>
)

export default BasicTitleText
