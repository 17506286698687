import React from "react"
import Icon from './Icon'

const ArrowDown = props => (
  <Icon viewBox="0 0 54 27" {...props}>
    <polyline
      fill="none" stroke="#444444"
      strokeWidth="1" strokeLinecap="square" strokeMiterlimit="10"
      points="1,1 27,26 53,1" transform="translate(0, 0)"
      strokeLinejoin="miter"
    ></polyline>
  </Icon>
);

export default ArrowDown
